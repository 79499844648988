import intl from "react-intl-universal";
import * as S from "./styled";
import { getDaysByRange } from "pages/load-composition/utils";
import moment from "moment";
import { DATA_FORMAT_TYPES, getDataFormat } from "utils/format-date";

const HeaderTableRight = () => {
  const dates = getDaysByRange(
    moment().format("YYYY-MM-DD"),
    moment().add(14, "days").format("YYYY-MM-DD")
  );

  return (
    <S.TableHeader>
      <S.TableHeadRow>
        <S.TableHeadCell isReduced={false}>
          <S.TableHeadRow className="title-date-row">
            <S.TableHeadCell isReduced={true}>
              <S.CellText>{intl.get("supplierProductionPlan.table.productionByDay")}</S.CellText>
            </S.TableHeadCell>
          </S.TableHeadRow>
          <S.TableHeadRow>
            {dates.map((date) => (
              <S.TableHeadCell
                key={date}
                isReduced={true}
                className="date-cell"
             >
                <S.CellText>{getDataFormat(date, DATA_FORMAT_TYPES.WITH_YEAR)}</S.CellText>
              </S.TableHeadCell>
            ))}
          </S.TableHeadRow>
        </S.TableHeadCell>
      </S.TableHeadRow>
    </S.TableHeader>
  );
};

export default HeaderTableRight;
