import Header from "components/header";
import intl from "react-intl-universal";
import SupplierProductionPlanFilter from "../supplier-production-plan-filter";
import * as S from "./styled";
import SupplierProductionPlanContent from "../supplier-production-plan-content";

export const SupplierProductionPlan = () => {
  return (
    <>
      <div style={{ marginTop: '32px' }}>
        <Header
          title={intl.get("supplierProductionPlan.header.title")}
          titleWidth={700}
          noBackground
        >
          {/* 
          //@ts-ignore */}
          <S.WrapperActions>
            <SupplierProductionPlanFilter />
          </S.WrapperActions>
        </Header>
      </div>
      <SupplierProductionPlanContent />
    </>
  );
};
