import { IProductionData } from "features/supplier-production-plan/interface";
import * as S from './styled'
import HeaderTableLeft from "./table-left-header";
import BodyTableLeft from "./table-left-body";

interface ISupplierProductionPlanTable {
  data: IProductionData[];
}

const SupplierProductionPlanTableLeft = ({ data }: ISupplierProductionPlanTable) => (
  <S.WrapperLeftTable>
    <HeaderTableLeft />
    <BodyTableLeft data={data} />
  </S.WrapperLeftTable>
);

export default SupplierProductionPlanTableLeft;
