import { IProductionData } from "features/supplier-production-plan/interface";
import * as S from './styled'
import HeaderTableRight from "./table-right-header";
import ScrollContainer from "react-indiana-drag-scroll";
import BodyTableRight from "./table-right-body";

interface ISupplierProductionPlanTable {
  data: IProductionData[];
}

const SupplierProductionPlanTableRight = ({ data }: ISupplierProductionPlanTable) => (
  <S.WrapperRightTable>
    <ScrollContainer>
      <HeaderTableRight />
      <BodyTableRight data={data} />
    </ScrollContainer>
  </S.WrapperRightTable>
);

export default SupplierProductionPlanTableRight;
