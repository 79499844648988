import Pagination from 'components/pagination'
import * as S from './styled'
import { useSupplierProductionPlanContext } from 'features/supplier-production-plan/context';

const SupplierProductionPlanPagination = () => {
  const { filters, setFilters, quantityRegisters } = useSupplierProductionPlanContext();

  const handlePagination = (value: number) => {
    setFilters((prevState: any) => ({
      ...prevState,
      _page: value
    }))
  }

  return (
    <S.Wrapper>
      <S.Content>
        <Pagination
          refreshSearch={handlePagination}
          page={filters._page}
          pageSize={filters._size}
          amount={quantityRegisters}
        />
      </S.Content>
    </S.Wrapper>
  )
};

export default SupplierProductionPlanPagination;
