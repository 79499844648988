import { useSupplierProductionPlanContext } from "features/supplier-production-plan/context";
import { validatesArrayHasValue } from "utils/custom-functions";
import * as S from "./styled";
import intl from "react-intl-universal";
import SupplierProductionPlanTable from "../supplier-production-plan-table";
import SupplierProductionPlanUpload from "../supplier-production-plan-upload";
import SupplierProductionPlanPagination from "../supplier-production-plan-pagination";

const SupplierProductionPlanContent = () => {
  const { productionPlanData } = useSupplierProductionPlanContext();

  return (
    <>
      <SupplierProductionPlanUpload />

      {(!productionPlanData || !validatesArrayHasValue(productionPlanData?.Data)) && 
        <S.NoDataFound>{intl.get("commons.noRecordsFound")}</S.NoDataFound>
      }

      {(productionPlanData && validatesArrayHasValue(productionPlanData.Data)) && 
          <S.TableWrapper>
            <SupplierProductionPlanTable data={productionPlanData.Data} />
            <SupplierProductionPlanPagination />
          </S.TableWrapper>
      }
    </>
  );
};

export default SupplierProductionPlanContent;
