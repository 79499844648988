import { createContext, useContext, useMemo, useState } from "react";
import StorePersist from 'utils/store-persist'
import { mountQuery, supplierProductionPlanFiltersDefault } from "./utils";
import Loading from "components/center-loading";
import { IProductionPlanPaginated, ISupplierProductionPlanFilters, SupplierProductionPlanContextProps, SupplierProductionPlanProviderProps } from "./interface";
import { getSupplierProductionPlan } from "services/supplier-production-plan.service";

const SupplierProductionPlanContext = createContext<SupplierProductionPlanContextProps | undefined>(undefined);

const SupplierProductionPlanProvider: React.FC<SupplierProductionPlanProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<ISupplierProductionPlanFilters>({
    ...(StorePersist.getValuesJSON("filtersSupplierProductionPlan") ?? supplierProductionPlanFiltersDefault)
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [productionPlanData, setProductionPlanData] = useState<IProductionPlanPaginated | null>(null);
  const [supplierGroups, setSupplierGroups] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [quantityRegisters, setQuantityRegisters] = useState(0);

  const searchProductionPlanData = async (filters: ISupplierProductionPlanFilters) => {
    StorePersist.setValuesJSON("filtersSupplierProductionPlan", filters);
    setFilters(filters);
    const _query = mountQuery(filters);
    const _result = await getSupplierProductionPlan(_query);
    setProductionPlanData(_result);
    setQuantityRegisters(_result.TotalItems);
  }

  const values = useMemo(() => ({
    filters,
    setFilters,
    loading,
    setLoading,
    productionPlanData,
    setProductionPlanData,
    searchProductionPlanData,
    supplierGroups,
    setSupplierGroups,
    suppliers, 
    setSuppliers,
    materials, 
    setMaterials,
    quantityRegisters
  }), [filters, loading, productionPlanData]);

  return (
    <SupplierProductionPlanContext.Provider value={values}>
      {children}
      <Loading isLoading={loading} />
    </SupplierProductionPlanContext.Provider>
  );
};

const useSupplierProductionPlanContext = () => {
  const context = useContext(SupplierProductionPlanContext);
  if (!context) {
    throw new Error("useSupplierProductionPlanContext must be used within a SupplierProductionPlanProvider");
  }
  return context;
};

export {
  SupplierProductionPlanProvider,
  SupplierProductionPlanContext,
  useSupplierProductionPlanContext,
};
