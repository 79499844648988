import { IProductionData } from "features/supplier-production-plan/interface";
import * as S from './styled'
import SupplierProductionPlanTableLeft from "./table-left";
import SupplierProductionPlanTableRight from "./table-right";

interface ISupplierProductionPlanTable {
  data: IProductionData[];
}

const SupplierProductionPlanTable = ({ data }: ISupplierProductionPlanTable) => (
  <S.WrapperTable>
    <SupplierProductionPlanTableLeft data={data} />
    <SupplierProductionPlanTableRight data={data} />
  </S.WrapperTable>
);

export default SupplierProductionPlanTable;
