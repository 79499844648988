import { useState } from 'react';
import intl from "react-intl-universal";
import * as S from './styled';
import { ClbButton } from '@celebration/design-system-react';
import ImportExportSupplierProductionPlan from 'components/import-export-supplier-production-plan';

const SupplierProductionPlanUpload = () => {
  const [openUpload, setOpenUpload] = useState(false);

  const close = () => setOpenUpload(false);

  return(
    <S.UploadSectionWrapper>
      <ClbButton 
        textContent={intl.get('exportImportModal.butttons.upload')}
        onClbClick={() => setOpenUpload(!openUpload)}
      />
      <ImportExportSupplierProductionPlan isOpen={openUpload} close={close} />
    </S.UploadSectionWrapper>
  )
}

export default SupplierProductionPlanUpload;