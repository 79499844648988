import { useState } from "react";
import { Button } from "components/uikit-adapter";
import intl from "react-intl-universal";
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'
import { verifyIsFiltered } from "utils/custom-functions";
import * as S from "./styled";
import { useSupplierProductionPlanContext } from "features/supplier-production-plan/context";
import SidebarFilter from "./sidebar-filter";
import { OptionsTheme } from "pages/master-data/configurations/general-settings/models/generalSettingsModel";
import { ISupplierProductionPlanFilters } from "features/supplier-production-plan/interface";
import { useToastContext } from "hooks/useToastContext";
import { formatErrorMessage } from "utils/handle-error";

const SupplierProductionPlanFilter = () => {
  const { filters, setLoading, searchProductionPlanData } = useSupplierProductionPlanContext();
  const { openToast } = useToastContext();
  const [openFilter, setOpenFilter] = useState(false);

  const getIconFilter = () => {
    const theme = Number(localStorage.getItem("THEME"));

    if (Number(theme) != OptionsTheme.DesignSystem) {
      if (verifyIsFiltered(filters)) return <IconFilterActive fillColor="#55c9d8" />;
      else return <IconFilterInactive />;
    } else return undefined;
  };

  const closeFilter = () => setOpenFilter(false);

  const search = async (filtersSelected: ISupplierProductionPlanFilters) => {
    try {
      setLoading(true);
      await searchProductionPlanData(filtersSelected);
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative');
    } finally {
      setLoading(false);
      closeFilter();
    }
  };

  return (
    <S.Wrapper>
      <Button
        onClick={() => setOpenFilter((prevState) => !prevState)}
        type="default"
        buttonText={intl.get("filters.filter")}
        buttonIcon={getIconFilter()}
        className='button-filter-supplier-production-plan'
      />
      <SidebarFilter open={openFilter} closeFilter={closeFilter} search={search} />
    </S.Wrapper>
  );
};

export default SupplierProductionPlanFilter;
