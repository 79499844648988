import styled from "styled-components";

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  margin: 0 auto;
  padding-bottom: 12px;
  max-height: 50vh;
  overflow: hidden auto;
  padding: 0px 10px;

  button {
    margin: 8px 0 16px 0px;
    min-height: 40px;
  }
`