import { formatNumber } from "utils/format";
import * as S from "./styled";
import { IProductionData } from "features/supplier-production-plan/interface";

interface ISupplierProductionPlanTable {
  data: IProductionData[];
}

const BodyTableLeft = ({ data } : ISupplierProductionPlanTable) => {
  const verifyNumberIsOdd = (value: number) => value % 2 !== 0;

  return (
    <S.TableBody>
      {data.map((x, index) => (
        <S.TableBodyRow key={`${x.SupplierId}-${x.MaterialId}`}>
          <S.TableBodyCell isOdd={verifyNumberIsOdd(index+1)} className="description-cell"> 
            <S.BodyCellText title={`${x.SupplierCode} - ${x.SupplierDescription}`}>{x.SupplierCode} - {x.SupplierDescription}</S.BodyCellText> 
          </S.TableBodyCell>
          <S.TableBodyCell isOdd={verifyNumberIsOdd(index+1)} className="description-cell"> 
            <S.BodyCellText  title={x.MaterialDescription}>{x.MaterialDescription} </S.BodyCellText>
          </S.TableBodyCell>
          <S.TableBodyCell isOdd={verifyNumberIsOdd(index+1)} className="description-cell"> 
            <S.BodyCellText  title={x.Family}> {x.Family} </S.BodyCellText>
          </S.TableBodyCell>
          <S.TableBodyCell isOdd={verifyNumberIsOdd(index+1)} className="description-cell"> 
            <S.BodyCellText  title={x.SupplierOrigin}> {x.SupplierOrigin} </S.BodyCellText>
          </S.TableBodyCell>
          <S.TableBodyCell isOdd={verifyNumberIsOdd(index+1)} className="quantity-cell"> 
            <S.BodyCellText  title={`${x.TodayStock}`}> {formatNumber(x.TodayStock)} </S.BodyCellText>
          </S.TableBodyCell>
          <S.TableBodyCell isOdd={verifyNumberIsOdd(index+1)} className="quantity-cell"> 
            <S.BodyCellText  title={`${x.Total}`}> {formatNumber(x.Total)} </S.BodyCellText>
          </S.TableBodyCell>
        </S.TableBodyRow>
      ))}
    </S.TableBody>
  );
};

export default BodyTableLeft;
