import { useSupplierProductionPlanContext } from "features/supplier-production-plan/context";
import { useEffect, useState } from "react";
import * as S from "./styled";
import intl from "react-intl-universal";
import { supplierProductionPlanFiltersDefault } from "features/supplier-production-plan/utils";
import { searchMaterials, searchSuppliers, searchSupplierUsers } from "services/filters.service";
import { getUserId, isUseFilterDefault, isUserSupplier } from "utils/user";
import PropTypes from 'prop-types';
import { ClbDrawer } from "libs/celebration";
import { SelectBox } from "components/uikit-adapter";

const SidebarFilter = ({ open, closeFilter, search }) => {
  const { 
    filters,
    setLoading,
    searchProductionPlanData,
    supplierGroups,
    setSupplierGroups,
    suppliers,
    setSuppliers,
    materials,
    setMaterials
  } = useSupplierProductionPlanContext();
  const [filtersSelected, setFiltersSelected] = useState(filters);

  const loadSupplierUsersOptions = async () => {
    let _supplierGroup = filtersSelected.SupplierGroups;
    const _supplierUsers = await searchSupplierUsers();
    setSupplierGroups(_supplierUsers);

    if(isUserSupplier()) {
      const _userId = getUserId();
      const _supplierGroupsFilterIds = filtersSelected.SupplierGroups.map(x => x.Id);

      if(!_supplierGroupsFilterIds.includes(_userId) || filtersSelected.SupplierGroups.length() == 0) {
        _supplierGroup = _supplierUsers.filter(supplierUser => supplierUser.Id == _userId);

        setFiltersSelected(prevState => ({
          ...prevState,
          SupplierGroups: _supplierGroup
        }));
      }
    }

    return _supplierGroup;
  }

  const loadSuppliersOptions = async () => {
    const _suppliers = await searchSuppliers(isUseFilterDefault());
    setSuppliers(_suppliers);
  }

  const loadMaterialsOptions = async () => {
    const _materials = await searchMaterials(isUseFilterDefault());
    const _formatMaterials = _materials.map(material => ({...material, Id: material.MaterialNumber}));
    setMaterials(_formatMaterials);
  }

  const handleChange = (value, field) => {
    setFiltersSelected(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const handleSearch = async (filters) => {
    await search(filters);
  };

  const clearFilter = () => {
    let _supplierGroups = supplierProductionPlanFiltersDefault.SupplierGroups;

    if(isUserSupplier())  _supplierGroups = filtersSelected.SupplierGroups;

    const _filters = {
      ...supplierProductionPlanFiltersDefault,
      SupplierGroups: _supplierGroups
    };

    setFiltersSelected(_filters);
    searchProductionPlanData(_filters);
    
    closeFilter();
  };

  const loadFilters = async () => {
    let _supplierGroup = filtersSelected.SupplierGroups;
    try {
      setLoading(true);
      _supplierGroup = await loadSupplierUsersOptions();
      await loadSuppliersOptions();
      await loadMaterialsOptions();
    } catch(e){
      console.error(e);
    } finally {
      handleSearch({ ...filtersSelected, SupplierGroups: _supplierGroup});
      setLoading(false);
    }    
  }

  useEffect(() => {   
    loadFilters();
  }, []);

  return (
    <ClbDrawer
      title={intl.get('filters.filter')}
      opened={open}
      onClbClose={closeFilter}
      buttonsList={[
        {
          id: "btn-close-drawer",
          label: intl.get('filters.cleanFilter'),
          title: intl.get('filters.cleanFilter'),
          dataTestid: "btn-close-drawer",
          icon: "ChevronLeft",
          iconPosition: "left",
          callback: () => clearFilter(),
        },
        {
          id: "btn-filter-drawer",
          label: intl.get('filters.filter'),
          title: intl.get('filters.filter'),
          dataTestid: "btn-filter-drawer",
          callback: () => handleSearch(filtersSelected),
        },
      ]}
    >
      <S.FilterContainer>
        <SelectBox
          name="SupplierGroups"
          key="SupplierGroups"
          label={intl.get("commons.supplierGroup")}
          placeholder={intl.get("commons.supplierGroup")}
          valueKey="Id"
          labelKey="Description"
          multi
          searchable
          clearable
          disabled={isUserSupplier()}
          value={filtersSelected.SupplierGroups}
          onChange={(value) => handleChange(value, "SupplierGroups")}
          options={supplierGroups}
        />
        <SelectBox
          name="Suppliers"
          key="Suppliers"
          label={intl.get("bottles.suppliers")}
          placeholder={intl.get("bottles.suppliers")}
          valueKey="Id"
          labelKey="Description"
          multi
          searchable
          clearable
          value={filtersSelected.Suppliers}
          onChange={(value) => handleChange(value, "Suppliers")}
          options={suppliers}
        />
        <SelectBox
          name="Materials"
          key="Materials"
          label={intl.get("commons.material")}
          placeholder={intl.get("commons.material")}
          valueKey="Id"
          labelKey="Description"
          multi
          searchable
          clearable
          value={filtersSelected.Materials}
          onChange={(value) => handleChange(value, "Materials")}
          options={materials}
        />
      </S.FilterContainer>
    </ClbDrawer>
  );
};

SidebarFilter.propTypes = {
  open: PropTypes.bool,
  closeFilter: PropTypes.func,
  search: PropTypes.func
}

export default SidebarFilter;
