import intl from "react-intl-universal";
import * as S from "./styled";

const HeaderTableLeft = () => {
  return (
    <S.TableHeader>
      <S.TableHeadRow>
        <S.TableHeadCell className="description-cell" isReduced={false}>
          <S.CellText> {intl.get("commons.supplier")} </S.CellText>
        </S.TableHeadCell>
        <S.TableHeadCell className="description-cell" isReduced={false}>
          <S.CellText>{intl.get("commons.material")} </S.CellText>
        </S.TableHeadCell>
        <S.TableHeadCell className="description-cell" isReduced={false}>
          <S.CellText> {intl.get("commons.family")} </S.CellText>
        </S.TableHeadCell>
        <S.TableHeadCell className="description-cell" isReduced={false}>
          <S.CellText>
            {intl.get("supplierProductionPlan.table.supplierOrigin")}
          </S.CellText>
        </S.TableHeadCell>
        <S.TableHeadCell className="quantity-cell" isReduced={false}>
          <S.CellText>
            {intl.get("supplierProductionPlan.table.todayStock")}
          </S.CellText>
        </S.TableHeadCell>
        <S.TableHeadCell isReduced={false} className="quantity-cell">
          <S.CellText>
            {intl.get("supplierProductionPlan.table.totalQty")}
          </S.CellText>
        </S.TableHeadCell>
      </S.TableHeadRow>
    </S.TableHeader>
  );
};

export default HeaderTableLeft;
