import { ISupplierProductionPlanFilters } from "./interface";
import { generateQueryParams } from "utils/custom-functions";

export const supplierProductionPlanFiltersDefault = {
  SupplierGroups: [],
  Suppliers: [],
  Materials: [],
  _page: 0,
  _size: 10
};

export const mountQuery = (obj: ISupplierProductionPlanFilters) => {
  const _query =  {
    "Filters.SupplierGroupsIds": obj.SupplierGroups?.map(item => item.Id),
    "Filters.Suppliers": obj.Suppliers?.map(item => item.Id),
    "Filters.Materials": obj.Materials?.map(item => item.Id),
    _page: obj._page,
    _size: obj._size
  }
  
  return generateQueryParams(_query);
}
