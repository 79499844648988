import styled from "styled-components";

export const Wrapper = styled.div`
  width: 120px;

  .sidebar-close {
    display: none;
  }

  .button-filter-supplier-production-plan{
    width: 110px;
    height: 32px;
    background-color: transparent !important;
  }

  .button-filter-supplier-production-plan:hover{
    background-color: var(--color-action-hover);
  }
`;

export const FilterContainer = styled.div`
  background: var(--color-contrast-white);
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
`;

export const FilterContainerFooter = styled.div`
  padding: 8px 0px;
  border-top: 1px solid var(--color-neutral-300);
`
