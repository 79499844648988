import { generateQueryParams } from "utils/custom-functions";
import { ISupplierProductionPlanFiltersImportExport } from "./interface";

export const filtersDefaultSupplierProductionPlanUpload = {
  SupplierGroupsIds: [],
  Suppliers: [],
  Materials: []
};

export const mountQueryObject = (filters: ISupplierProductionPlanFiltersImportExport) => {
  const _query =  {
      SupplierGroupsIds: filters.SupplierGroupsIds?.map(item => item.Id),
      Suppliers: filters.Suppliers?.map(item => item.Id),
      Materials: filters.Materials?.map(item => item.Id),
    }
    
    return generateQueryParams(_query);
}