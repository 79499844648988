import { formatNumber } from "utils/format";
import * as S from "./styled";
import { IProductionData } from "features/supplier-production-plan/interface";

interface ISupplierProductionPlanTable {
  data: IProductionData[];
}

const BodyTableRight = ({ data }: ISupplierProductionPlanTable) => {
  const verifyNumberIsOdd = (value: number) => value % 2 !== 0;

  return (
    <S.TableBody>
      {data.map((p, index) => (
        <S.TableBodyRow key={`${p.SupplierId}-${p.MaterialId}`}>
          {p.ProductionDates.map((x) => (
            <S.TableBodyCell isOdd={verifyNumberIsOdd(index + 1)} className="date-cell" key={x.ProductionDate}>
              <S.BodyCellText title={`${x.ProductionQuantity}`}>
                {formatNumber(x.ProductionQuantity)}
              </S.BodyCellText>
            </S.TableBodyCell>
          ))}
        </S.TableBodyRow>
      ))}
    </S.TableBody>
  );
};

export default BodyTableRight;
